<template>
    <div>
        <comp-table ref="table" title="社区生态商城分类 - 商家管理" :columns="columns" :table-api="tableApi" :delete-api="v => '/gateway/api/sycompany/pc/company/removeCompanyLabel?companyLabelId=' + v.id">
            <template #operate>
                <Button icon="ios-add" type="primary" @click="onDisplayTransfer">绑定商家</Button>
            </template>
        </comp-table>

        <CompModal ref="modal" title="绑定商家" :on-success="onSubmitBindCompany" @on-close="companyTargetKeys = []" isFull>
            <div class="classify-ecology-shuttle-box">
                <div class="table-box">
                    <div class="head-box">
                        <Input class="input" v-model="search" placeholder="输入单位名称" style="width:200px"></Input>
                        <Button class="btn" type="primary" @click="onSearch">搜索</Button>
                        <Button class="btn" type="success" @click="onReset">
                            重置
                        </Button>
                    </div>
                    <div class="table">
                        <ElemTable ref="addTable" isSelector :columns="addTableColumn" :tableApi="addTableApi" height="100%"></ElemTable>
                    </div>
                </div>
                <div class="operate-box">
                    <Button type="primary" class="btn" @click="onPostSelect">添加</Button>
                    <Button type="error" class="btn" @click="onRemoveSelect">删除</Button>
                </div>
                <div class="table-box">
                    <div class="head-box">
                        <p class="title">已选商家</p>
                    </div>
                    <div class="table">
                        <ElemTable ref="selectedTable" isSelector :columns="selectedTableColumn" :dataList="selectedList" height="100%"></ElemTable>
                    </div>
                </div>
            </div>
        </CompModal>
    </div>
</template>

<script>
import CompTable from "../../jointly/components/comp-table.vue"
import CompModal from "../../residentdatabase/components/CompModal.vue"
import ElemTable from "../../communitymobilization/components/ElemTable.vue"

export default {
    components: { CompTable, CompModal, ElemTable },

    data() {
        const labelId = this.$core.getUrlParam("labelId")

        return {
            labelId: labelId,
            tableApi: `/gateway/api/sycompany/pc/label/listCompanyByLabel?labelId=${labelId}`,
            columns: [
                {
                    title: "商家ID",
                    key: "companyId",
                    minWidth: 300,
                    align: "center",
                },
                {
                    title: "商家名称",
                    key: "companyName",
                    minWidth: 300,
                    align: "center",
                },
            ],
            // 商家列表
            companyList: [],
            companyTargetKeys: [],
            listStyle: {
                width: "250px",
                height: "500px",
            },

            // 搜索内容
            search: "",

            addTableColumn: [
                {
                    title: "单位名称",
                    key: "name",
                    align: "center",
                    minWidth: 200,
                },
            ],
            // 辖区共建列表接口
            addTableApi: `/gateway/api/sycompany/pc/company/getCompanyByPage?orgCode=${this.$core.getUserInfo().orgCode}&businessStatus=1&lifeDisplay=1`,
            selectedTableColumn: [
                {
                    title: "单位名称",
                    key: "name",
                    align: "center",
                    minWidth: 200,
                },
            ],
            selectedList: [],
        }
    },

    methods: {
        onDisplayTransfer() {
            if (!this.selectedList?.length) {
                const s = this.$refs.table.getDataList()
                this.selectedList = s.map(v => {
                    return {
                        companyId: v.companyId,
                        name: v.companyName,
                    }
                })
            }

            this.$refs.modal.display()
        },

        /**
         * 提交绑定商家
         */
        onSubmitBindCompany() {
            if (!this.selectedList?.length) {
                return this.$Message.warning("请选择商家！")
            }

            const load = this.$Message.loading("提交中...")
            this.$post(
                `/gateway/api/sycompany/pc/company/assignCompanyLabel`,
                {
                    labelIds: [this.labelId],
                    companyIds: this.selectedList.map(v => v.companyId),
                },
                { "Content-Type": "application/json" }
            )
                .then(res => {
                    if (res.code == 200) {
                        this.$Message.success("绑定成功！")
                        this.$refs.modal.close()
                        this.$refs.table.refresh()
                    } else this.$Message.error((res.desc || res.msg || "请求失败").slice(0, 50))
                })
                .finally(load)
        },

        onPostSelect() {
            const s = this.$refs.addTable.getSelecteds()

            if (!s?.length) {
                return this.$Message.warning("请选择需要添加的记录！")
            }

            const selected = this.selectedList

            // 过滤已选
            selected.push(...s.filter(f => selected.findIndex(sf => sf.companyId === f.companyId) == -1))

            this.$refs.addTable.clearSelecteds()
        },

        /**
         * 删除
         */
        onRemoveSelect() {
            const s = this.$refs.selectedTable.getSelecteds()

            if (!s?.length) {
                return this.$Message.warning("请选择需要删除的记录！")
            }

            const selected = this.selectedList

            for (let i = 0; i < s.length; i++) {
                let idx = selected.findIndex(v => v.companyId == s[i].companyId)

                if (idx > -1) {
                    selected.splice(idx, 1)
                }

                s.splice(i, 1)
                i--
            }
        },

        onSearch() {
            this.$refs.addTable.search({
                name: this.search,
            })
        },

        onReset() {
            this.search = ""
            this.$refs.addTable.reset()
        },
    },
}
</script>
<style lang="less">
.classify-ecology-shuttle-box {
    width: 100%;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    > .table-box {
        height: 100%;
        display: flex;
        flex-direction: column;

        &:first-child {
            width: calc(50% - 50px);
        }

        &:last-child {
            width: calc(50% - 50px);
        }

        .head-box {
            padding: 5px 0;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            flex-shrink: 0;

            .input {
                margin-right: 10px;
            }

            .btn {
                margin: 5px 10px 5px 0;
            }

            .title {
                text-align: center;
                width: 100%;
                font-size: 16px;
                margin: 10px 0;
                font-weight: 600;
            }
        }

        .table {
            width: 100%;
            flex-grow: 1;
            overflow: hidden;
        }
    }

    > .operate-box {
        width: 80px;
        margin: 40px 10px 0 10px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .btn {
            margin: 5px 0;
        }
    }
}
</style>
